import { useFormik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { downloadURL, IMAGE_URL, STUDENT_FILE_DOWNLOAD } from '../../../../utils/Constants'
import { TrimText } from '../../../common/TrimText'
import { useParams } from 'react-router-dom';
import { FileUploadField } from '../../../common/FormFields';
import { getAllfiles, getStudentEvidence, updateStudentEvidence, uploadUserFeedbackAndFile } from '../../../../services/CourseService';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import Swal from 'sweetalert2';
import axios from "axios";
import $ from 'jquery';
import SkeletonTicketList from '../../../../loaders/SkeletonTicketList';
import { download } from '../../../../utils/FileDownload';

function CriteriaSubmission({ studentDetailList , setStudentDetailList , fetchQuizQuestion , nextPrevious , fetchData }) {

  const {openTabs, subType , subId , subSubId } = useParams();
  const [loading, setLoading] = useState(false);
  const [previewPhotos, setPreviewPhotos] = useState([])
  const [expanded, setExpanded] = useState(false);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [previewVideos, setPreviewVideos] = useState([])
  const [previewDocument, setPreviewDocument] = useState([])
  const [accordionStates, setAccordionStates] = useState({
    photo: previewPhotos.length > 0 ? true : false,
    video: false,
    document: false
  });
  const [studentData, setStudentData] = useState({});
  const [downloadLoading, setDownloadLoading] = useState(false);
  const descriptionRef = useRef(null);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadedSize, setDownloadedSize] = useState('0 B'); // Initialize with a default value
  const [totalSizeFormatted, setTotalSizeFormatted] = useState('0 B'); // Initialize with a default value
  const [totalFiles, setTotalFiles] = useState(0);
  const [filesDownloaded, setFilesDownloaded] = useState(0);
  const [isDisabled, setIsDisabled] = useState({
    photo: false,
    video: false,
    document: false
  });
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      photo: [],
      video: [],
      document: [],
      lecturer_observation_toggle: 0,
      user_feedback: "",
      user_feedback_file: "",
      deleteIds: [],
      practical_submission_question_id : 0
    },
    onSubmit: (values) => {
      setLoading(true);

      const formData = new FormData();
      
      const trimmedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [
            key,
            typeof value === "string" ? value.trim().replace(/ +/g, " ") : value,
        ])
      );
          
      Object.keys(trimmedValues).forEach((key) => {
          if (Array.isArray(trimmedValues[key])) {
                  trimmedValues[key].forEach((val, idx) => {
                      if (val !== null) {
                          formData.append(`${key}[${idx}]`, val);
                      }
                  });
          } else {
              if (trimmedValues[key] !== null) {
                  formData.append(key, trimmedValues[key]);
              }
          }
      });

      if(subType == "openStudentAssessment") {
        formData.delete("photo");
        formData.delete("video");
        formData.delete("document");
        formData.delete("deleteIds");
        formData.delete("user_feedback_file");
      }

      if(subType == "openStudentAssessment") {
        uploadUserFeedbackAndFile(formData).then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated Successfully",
          });
          fetchData();
          if(studentDetailList != null) {
            studentDetail();
          }
        }).catch((err) => {
          console.log("err", err);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Something went wrong",
          });
        }).finally(() => {
          setLoading(false);
        })
      } else {
        updateStudentEvidence(formData).then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated Successfully",
          });
          fetchQuizQuestion();
          if(studentDetailList != null) {
            studentDetail();
          }
      }).catch((err) => {
          console.log("err", err);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Something went wrong",
          });
        }).finally(() => {
          setLoading(false);
        })
      }
    }
  });

  const description = studentData?.getSubmissionPracticalQuestionsBank?.description;

  useEffect(() => {
      const checkOverflow = () => {
          if (descriptionRef.current) {
              setShowSeeMore(descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight);
          }
      };

      checkOverflow();

      window.addEventListener('resize', checkOverflow);

      return () => {
          window.removeEventListener('resize', checkOverflow);
      };
  }, [description]);

  const handleToggle = () => {
      setExpanded(!expanded);
  };

  const handleUpload = (event, type) => {
    const files = Array.from(event.target.files);

    if(files.length > 0) {
      setIsDisabled(prevState => ({
      ...prevState,
      [type]: true
    }))
  }

    const photoFiles = files.filter(file => file.type.startsWith('image'));
    const videoFiles = files.filter(file => file.type.startsWith('video'));
    const documentFiles = files.filter(file =>
      file.type.startsWith('application/pdf') || 
      file.type.startsWith('application/msword') || 
      file.type.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml.document') || 
      file.type.startsWith('text/plain') || 
      file.type.startsWith('application/zip') || 
      file.type.startsWith('application/x-zip-compressed') || 
      file.type.startsWith('application/vnd.oasis.opendocument.text') || 
      file.type.startsWith('application/vnd.ms-excel') || 
      file.type.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || 
      file.type.startsWith('text/csv') || 
      file.type.startsWith('application/vnd.ms-powerpoint') || 
      file.type.startsWith('application/vnd.openxmlformats-officedocument.presentationml.presentation') || 
      file.type.startsWith('application/rtf') || 
      file.type.startsWith('application/vnd.oasis.opendocument.spreadsheet') || 
      file.type.startsWith('application/vnd.oasis.opendocument.presentation')
    );

    if (photoFiles.length > 0) {
      const updatedPhotos = [...formik.values.photo, ...photoFiles];
      formik.setFieldValue('photo', updatedPhotos);
      const previewPhotoURLs = photoFiles.map(file => URL.createObjectURL(file));
      setPreviewPhotos(prevState => [...prevState, ...previewPhotoURLs]);
      setIsDisabled(prevState => ({
        ...prevState,
        photo: false
      }));
    }

    if (videoFiles.length > 0) {
      const updatedVideos = [...formik.values.video, ...videoFiles];
      formik.setFieldValue('video', updatedVideos);
      const previewVideoURLs = videoFiles.map(file => URL.createObjectURL(file));
      setPreviewVideos(prevState => [...prevState, ...previewVideoURLs]);
      setIsDisabled(prevState => ({
        ...prevState,
        video: false
      }));
    }

    if (documentFiles.length > 0) {
      const updatedDocument = [...formik.values.document, ...documentFiles];
      formik.setFieldValue('document', updatedDocument);
      const previewDocumentURLs = documentFiles.map(file => URL.createObjectURL(file));
      setPreviewDocument(prevState => [...prevState, ...previewDocumentURLs]);
      setIsDisabled(prevState => ({
        ...prevState,
        document: false
      }));
    }
  };

  const handleDelete = (index , type , id) => {
    if (type === 'photo') {
      let updatedPreviews = [...previewPhotos];
      if(id){
        updatedPreviews = updatedPreviews.filter(item => item.id != id);
      } else {
        updatedPreviews.splice(index, 1);
      }
      setPreviewPhotos(updatedPreviews);
    } else if (type === 'video') {
      let updatedPreviews = [...previewVideos];
      if(id){
        updatedPreviews = updatedPreviews.filter(item => item.id != id);
      } else {
        updatedPreviews.splice(index, 1);
      }
      setPreviewVideos(updatedPreviews);
    } else if (type === 'document') {
      let updatedPreviews = [...previewDocument];
      if(id){
        updatedPreviews = updatedPreviews.filter(item => item.id != id);
      } else {
        updatedPreviews.splice(index, 1);
      }
      setPreviewDocument(updatedPreviews);
    }

    if(id){
      formik.setFieldValue('deleteIds', [...formik.values.deleteIds, id]);
    }
  };

  const toggleAccordion = (section) => {
    setAccordionStates(prevState => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };

  const formatTime = (seconds) => {
    const date = new Date(seconds * 1000);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedHours = hours % 12 || 12;
    return `${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  const formatDate = (seconds) => {
    const date = new Date(seconds * 1000);
    // const formattedDate = date.toLocaleDateString();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const formattedDate = `${String(date.getDate()).padStart(2, '0')} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;

    return formattedDate;
  };
  
  const handleDownloadAll = async () => {
    setDownloadLoading(true);
    setDownloadProgress(0);
    setFilesDownloaded(0);

    try {
      const response = await getAllfiles(formik.values.practical_submission_question_id);
      const files = response.data.Data;
  
      if (!files || files.length === 0) {
        console.log("No files to download.");
        $("#downloadAssessmentAll").modal("hide");
        setDownloadProgress(0);
        setFilesDownloaded(0);
        setDownloadLoading(false);
        Swal.fire({
          icon: "error",
          title: "No files to download",
        });
        return;
      }
  
      setTotalFiles(files.length);
      const zip = new JSZip();
      const folder = zip.folder("files");
  
      const fileFetchPromises = files.map(async (file) => {
        if (!file.file_url) {
          console.error("Invalid file data:", file);
          return;
        }
    
        const fileURL = (file?.file_url.startsWith("http") || file?.file_url.startsWith("https")) ? downloadURL !== ""  ? downloadURL + file?.file_url.replace("/home/myaie/public_html/", "").replace("public/", "").replace(/https?:\/\//, "")
                        : file?.file_url.replace("/home/myaie/public_html/", "").replace("public/", "")
                        : (downloadURL !== "" ? downloadURL + IMAGE_URL?.replace(/https?:\/\//, "") : IMAGE_URL) + (file?.file_url.startsWith("/") ? "" : "/") +
                          file?.file_url.replace("/home/myaie/public_html/", "").replace("public/", "")
        try {
          const response = await axios({
            url: fileURL,
            method: "get",
            responseType: "blob",
            headers: {
              "Content-Type": "application/octet-stream",
              "access-control-expose-headers": "content-length"
            },
            onDownloadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              if (total) {
                const percentCompleted = Math.round((loaded * 100) / total);
                setDownloadProgress(prev => 
                  Math.min(100, Math.round((filesDownloaded + 1) / totalFiles * 100))
                )

                const currentSize = formatFileSize(loaded);
                setDownloadedSize(currentSize);
              }
            }
          });
  
          if (response.status === 200) {
            const fileName = file?.file_url?.split("/").pop();

            const blob = new Blob([response.data], { 
              type: response.headers['content-type'] || 'application/octet-stream'
            });
            
            folder.file(fileName, blob);
            setFilesDownloaded(prev => prev + 1);
          } else {
            Swal.fire({
              icon: "error",
              title: "Error while downloading file",
            });
            throw new Error(`Failed to fetch file: ${response.statusText}`);
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error while downloading file",
          });
          console.error(`Error downloading file ${file.file_name}:`, error);
          throw error;
        }
      });
  
      await Promise.all(fileFetchPromises);
      
      const zipContent = await zip.generateAsync({ type: "blob" });
      const timestamp = new Date().toISOString().split('T')[0];
      FileSaver.saveAs(zipContent, `downloaded_files_${timestamp}.zip`);
      
      // Reset states
      setDownloadProgress(0);
      setFilesDownloaded(0);
      setDownloadLoading(false);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Downloaded Successfully",
      });
      $("#downloadAssessmentAll").modal("hide");
      
    } catch (error) {
      $("#downloadAssessmentAll").modal("hide");
      console.error("Error in handleDownloadAll:", error);
      Swal.fire({
        icon: "error",
        title: "Error while downloading file",
      });
      setDownloadProgress(0);
      setFilesDownloaded(0);
      setDownloadLoading(false);
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 B';
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
  };

  const studentDetail = () => {
    setIsLoading(true);

    getStudentEvidence(subType == "openStudentAssessment" ? subSubId : studentDetailList , subId , subType == "openStudentAssessment" ? studentDetailList : subSubId).then((res) => {
      formik.setFieldValue("lecturer_observation_toggle", res?.data?.Data?.lecturer_observation_toggle == 1 ? 1 : 0);
      formik.setFieldValue("user_feedback", res?.data?.Data?.user_feedback);
      formik.setFieldValue("user_feedback_file", res?.data?.Data?.user_feedback_file);
      formik.setFieldValue("practical_submission_question_id", res?.data?.Data?.id);
      setStudentData(res?.data?.Data);

      const videoFiles = [];
      const photoFiles = [];
      const documentFiles = [];

      if (res?.data?.Data?.getSubmissionPracticalFiles) {
        res.data.Data.getSubmissionPracticalFiles.map(file => {
          if(file.type == 0) {
              photoFiles.push(file);
          } else if(file.type == 1) {
              videoFiles.push(file);
          } else if(file.type == 2) {
              documentFiles.push(file);
          }
        });
      }

      if (photoFiles.length > 0) {
        setPreviewPhotos(photoFiles);
      }

      if (videoFiles.length > 0) {
        setPreviewVideos(videoFiles);
      }

      if (documentFiles.length > 0) {
        setPreviewDocument(documentFiles);
      }

    }).catch((err) => {
      console.log("err", err);
    }).finally(() => {
      setIsLoading(false);
    });
  }  

  useEffect(() => {
    if(studentDetailList || nextPrevious == true || studentDetailList != null) {
      studentDetail();
    } 

     return () => {
      setStudentDetailList(null);
      setPreviewDocument([]);
      setPreviewPhotos([]);
      setPreviewVideos([]);
      setAccordionStates({
        photo: false,
        video: false,
        document: false
      });
      formik.resetForm();
     }
  } , [studentDetailList , nextPrevious ])

  const handleDownload = (fileURL) => {
    const downloadUrl = (fileURL.startsWith("http") || fileURL.startsWith("https")) ? downloadURL !== ""  ? downloadURL + fileURL.replace("/home/myaie/public_html/", "").replace("public/", "").replace(/https?:\/\//, "")
    : fileURL.replace("/home/myaie/public_html/", "").replace("public/", "")
    : (downloadURL !== "" ? downloadURL + IMAGE_URL?.replace(/https?:\/\//, "") : IMAGE_URL) + (fileURL.startsWith("/") ? "" : "/") + fileURL.replace("/home/myaie/public_html/", "").replace("public/", "")
   
    download(downloadUrl, downloadUrl.split('/').pop(), false);
  };

  return (
    <>
      {isLoading ? <SkeletonTicketList /> : <div className='mb-3'>
        <div style={{ fontFamily: "Segoe UI" , fontSize: 18 , fontWeight: 600 , lineHeight: "19.2px" }}>{(subType == "questionDetails" || subType == "openStudentAssessment") && studentData?.getSubmissionPracticalQuestionsBank?.question_name}</div>
        <div className={`description-container ${subType == "questionDetails" || subType == "openStudentAssessment" && "mb-3"}`}>
          <p
            ref={descriptionRef}
            style={{ paddingTop: 10 , fontFamily: "Segoe UI" , fontSize: 14 , fontWeight: 400 , lineHeight: "19.2px" }}
            className={`description-p ${expanded ? 'expanded' : 'collapsed'}`}
            dangerouslySetInnerHTML={{ __html: description }}
          />

          {showSeeMore && (
            <span onClick={handleToggle} className="toggle-button">
              {expanded ? 'See Less' : 'See More'}
            </span>
          )}
        </div>
      </div>}

      {isLoading ? <SkeletonTicketList /> : <form onSubmit={formik.handleSubmit}>
        <div className="row">
          {/* Photo Evidence Accordion */}
          {studentData?.getSubmissionPracticalQuestionsBank?.evidence_photo == 1 && <div className="col-12">
            <div className="accordion ac_over_none" id="accordionExample">
              <div className="card-main">
                <div className="card-top">
                  <div className="card-header1" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link align-items-center"
                        type="button"
                        style={{ cursor: "default" }}
                      >
                        <div className='evidence_Type' title="Photo Evidence" onClick={previewPhotos.length > 0 ? () => toggleAccordion('photo') : null} style={{ cursor: previewPhotos.length > 0 ? "pointer" : "default" }}>
                          <i className="fal fa-camera mr-1"></i> Photo Evidence <i className={`fal fa-${previewPhotos.length > 0 ? "check-circle" : "times-circle"} ${previewPhotos.length > 0 ? "text-success" : "text-danger"} mr-0`}></i>
                        </div>

                        <div className="d-flex align-items-center">
                          {openTabs == "assessmentMarking" && <div className="form-group atttach-file mr-1">
                            <label
                              className={formik.errors.photo && formik.touched.photo && " file-req is-invalid"}
                              title="Upload Files"
                            >
                              {isDisabled.photo ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-paperclip"></i>}
                              <span>Upload Files</span>
                              <input
                                type="file"
                                id="photos"
                                className="form-control form-control-aatch-file"
                                name="photos"
                                accept="image/*"
                                multiple
                                disabled={isDisabled.photo}
                                onChange={(event) => handleUpload(event, 'photo')}
                              />
                            </label>
                          </div>}

                          {previewPhotos.length > 0 && <div className="header-status" style={{ cursor: "pointer" }} onClick={() => toggleAccordion('photo')}>
                            <div title={accordionStates.photo ? 'up' : 'down'}>
                              <i className={`fal fa-chevron-${accordionStates.photo ? 'up' : 'down'} rotate-icon`} style={{ color: "#467FCF" }}></i>
                            </div>
                          </div>}
                        </div>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseOne"
                    className={`collapse mt-2 ${accordionStates.photo ? 'show' : ''}`}
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="row">
                      {previewPhotos.length > 0 && previewPhotos.map((fileURL, index) => (
                        <div key={index} className="col-md-6 col-lg-4 col-xl-3 mb-3">
                          <div className="card card-body border-0">
                            <div className="frm-group">
                                  <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div>
                                        <a href={fileURL?.file_url ? `${IMAGE_URL}${fileURL?.file_url.replace(/\/home\/myaie\/public_html\//, "").replace("public/", "")}` : fileURL} target="_blank" rel="noopener noreferrer" title={(typeof fileURL === 'string' ? fileURL.split("/").pop() : fileURL?.file_url?.split("/").pop())}>
                                          <i className="fal fa-file"></i>&nbsp;{(typeof fileURL === 'string' ? TrimText(fileURL.split("/").pop(), 20) : TrimText(fileURL?.file_url?.split("/").pop(), 20))}
                                        </a>
                                    </div>

                                    <div>
                                      {
                                        fileURL?.file_url ? 
                                        <a
                                          onClick={() => handleDownload(fileURL?.file_url)}
                                          className="btn btn-primary rounded-circle"
                                          title="Download"
                                          style={{ marginRight: 10 }}
                                        >
                                        <i className="fal fa-download"></i>
                                        </a> 
                                        :
                                        <a
                                          href={fileURL}
                                          download
                                          className="btn btn-primary rounded-circle"
                                          title="Download"
                                          style={{ marginRight: 10 }}
                                        >
                                        <i className="fal fa-download"></i>
                                        </a>
                                      }

                                      {openTabs == "assessmentMarking" && <button
                                        type="button"
                                        className="btn btn-danger rounded-circle"
                                        title="Delete"
                                        onClick={() => handleDelete(index, 'photo' , fileURL?.id)}
                                      >
                                        <i className="fal fa-trash-alt"></i>
                                      </button>}
                                    </div>
                                  </div>
                            </div>

                            <div className="frm-group">
                              <img height={264.11} width={185.82} src={fileURL?.file_url ? `${IMAGE_URL}${fileURL?.file_url.replace(/\/home\/myaie\/public_html\//, "").replace("public/", "")}` : fileURL} alt={(typeof fileURL === 'string' ? TrimText(fileURL.split("/").pop(), 20) : TrimText(fileURL?.file_url?.split("/").pop(), 20))} className="w-100" />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>}

          {/* Video Evidence Accordion */}
          {studentData?.getSubmissionPracticalQuestionsBank?.evidence_video == 1 && <div className="col-12">
            <div className="accordion ac_over_none" id="accordionExample">
              <div className="card-main">
                <div className="card-top">
                  <div className="card-header1" id="headingTwo">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link align-items-center"
                        type="button"
                        style={{ cursor: "default" }}
                      >
                        <div className='evidence_Type' title="Video Evidence" onClick={previewVideos.length > 0 ? () => toggleAccordion('video') : null} style={{ cursor: previewVideos.length > 0 ? "pointer" : "default" }}>
                          <i className="fal fa-video mr-1"></i> Video Evidence <i className={`fal fa-${previewVideos.length > 0 ? "check-circle" : "times-circle"} ${previewVideos.length > 0 ? "text-success" : "text-danger"} mr-0`}></i>
                        </div>

                        <div className="d-flex align-items-center">
                          {openTabs == "assessmentMarking" && <div className="form-group atttach-file mr-1">
                            <label
                              className={formik.errors.video && formik.touched.video && " file-req is-invalid"}
                              title="Upload Files"
                            >
                              {isDisabled.video ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-paperclip"></i>}
                              <span>Upload Files</span>
                              <input
                                type="file"
                                id="video"
                                className="form-control form-control-aatch-file"
                                name="video"
                                accept="video/*"
                                multiple
                                disabled={isDisabled.video}
                                onChange={(event) => handleUpload(event, 'video')}
                              />
                            </label>
                          </div>}

                          {previewVideos.length > 0 && <div className="header-status" style={{ cursor: "pointer" }} onClick={() => toggleAccordion('video')}>
                            <div title={accordionStates.video ? 'up' : 'down'}>
                              <i className={`fal fa-chevron-${accordionStates.video ? 'up' : 'down'} rotate-icon`} style={{ color: "#467FCF" }}></i>
                            </div>
                          </div>}
                        </div>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className={`collapse mt-2 ${accordionStates.video ? 'show' : ''}`}
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="row">
                      {previewVideos.length > 0 && previewVideos.map((fileURL, index) => (
                        <div key={index} className="col-md-6 col-lg-4 col-xl-3 mb-3">
                          <div className="card card-body border-0">
                            <div className="frm-group">
                                  <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div>
                                        <a href={fileURL?.file_url ? `${IMAGE_URL}${fileURL?.file_url.replace(/\/home\/myaie\/public_html\//, "").replace("public/", "")}` : fileURL} target="_blank" rel="noopener noreferrer" title={(typeof fileURL === 'string' ? fileURL.split("/").pop() : fileURL?.file_url?.split("/").pop())}>
                                          <i className="fal fa-file"></i>
                                        &nbsp;
                                        {(typeof fileURL === 'string' ? TrimText(fileURL.split("/").pop(), 20) : TrimText(fileURL?.file_url?.split("/").pop(), 20))}
                                        </a>
                                    </div>

                                    <div>
                                      {
                                        fileURL?.file_url ? 
                                        <a
                                          onClick={() => handleDownload(fileURL?.file_url)}
                                          className="btn btn-primary rounded-circle"
                                          title="Download"
                                          style={{ marginRight: 10 }}
                                        >
                                          <i className="fal fa-download"></i>
                                        </a> 
                                        :
                                        <a
                                          href={fileURL}
                                          download
                                          className="btn btn-primary rounded-circle"
                                          title="Download"
                                          style={{ marginRight: 10 }}
                                        >
                                          <i className="fal fa-download"></i>
                                        </a>
                                      }

                                      {openTabs == "assessmentMarking" && <button
                                        type="button"
                                        className="btn btn-danger rounded-circle"
                                        title="Delete"
                                        onClick={() => handleDelete(index, 'video' , fileURL?.id)}
                                      >
                                        <i className="fal fa-trash-alt"></i>
                                      </button>}
                                    </div>
                                </div>
                            </div>

                            <div className="frm-group" style={{ width: '100%', position: 'relative' }}>
                              <iframe 
                                srcdoc={`
                                  <html>
                                    <head>
                                      <style>
                                        body, html {
                                          margin: 0;
                                          padding: 0;
                                          height: 100%;
                                          overflow: hidden;
                                        }
                                        video {
                                          width: 100%;
                                          height: 264.11px;
                                          object-fit: cover;
                                        }
                                      </style>
                                    </head>
                                    <body>
                                      <video controls>
                                        <source src='${fileURL?.file_url ? `${IMAGE_URL}${fileURL?.file_url.replace(/\/home\/myaie\/public_html\//, "").replace("public/", "")}` : fileURL}' type='video/mp4'>
                                        Your browser does not support the video tag.
                                      </video>
                                    </body>
                                  </html>
                                `}
                                width="100%"
                                height="264.11px"
                                style={{ border: "none" }}
                                allowFullScreen
                              >
                              </iframe>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>}

          {/* Document Evidence Accordion */}
          {studentData?.getSubmissionPracticalQuestionsBank?.evidence_document == 1 && <div className="col-12">
            <div className="accordion ac_over_none" id="accordionExample">
              <div className="card-main">
                <div className="card-top">
                  <div className="card-header1" id="headingTwo">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link align-items-center"
                        type="button"
                        style={{ cursor: "default" }}
                      >
                        <div className='evidence_Type' title="Document Evidence" onClick={previewDocument.length > 0 ? () => toggleAccordion('document') : null} style={{ cursor: previewDocument.length > 0 ? "pointer" : "default" }}>
                          <i className="fal fa-file-alt mr-1"></i> Document Evidence <i className={`fal fa-${previewDocument.length > 0 ? "check-circle" : "times-circle"} ${previewDocument.length > 0 ? "text-success" : "text-danger"} mr-0`}></i>
                        </div>

                        <div className="d-flex align-items-center">
                          {openTabs == "assessmentMarking" && <div className="form-group atttach-file mr-1">
                            <label
                              className={formik.errors.document && formik.touched.document && " file-req is-invalid"}
                              title="Upload Files"
                            >
                              {isDisabled.document ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-paperclip"></i>}
                              <span>Upload Files</span>
                              <input
                                type="file"
                                id="document"
                                className="form-control form-control-aatch-file"
                                name="document"
                                accept=".pdf,.doc,.docx,.txt,.zip,.otf,.xls,.xlsx,.csv,.ppt,.pptx,.rtf,.odt,.ods,.odp"
                                multiple
                                disabled={isDisabled.document}
                                onChange={(event) => handleUpload(event, 'document')}
                              />
                            </label>
                          </div>}

                          {previewDocument.length > 0 && <div className="header-status" style={{ cursor: "pointer" }} onClick={() => toggleAccordion('document')}>
                            <div title={accordionStates.document ? 'up' : 'down'}>
                              <i className={`fal fa-chevron-${accordionStates.document ? 'up' : 'down'} rotate-icon`} style={{ color: "#467FCF" }}></i>
                            </div>
                          </div>}
                        </div>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className={`collapse mt-2 ${accordionStates.document ? 'show' : ''}`}
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="row">
                      {previewDocument.length > 0 && previewDocument.map((fileURL, index) => (
                        <div key={index} className="col-md-6 col-lg-4 col-xl-3 mb-3">
                          <div className="card card-body border-0">
                            <div className="frm-group">
                              <div className="d-flex justify-content-between align-items-center mb-3">
                                <div>
                                  <a href={fileURL?.file_url ? `${IMAGE_URL}${fileURL?.file_url.replace(/\/home\/myaie\/public_html\//, "").replace("public/", "")}` : fileURL} target="_blank" rel="noopener noreferrer" title={(typeof fileURL === 'string' ? fileURL.split("/").pop() : fileURL?.file_url?.split("/").pop())}>
                                    <i className="fal fa-file"></i>
                                    &nbsp;
                                    {(typeof fileURL === 'string' ? TrimText(fileURL.split("/").pop(), 20) : TrimText(fileURL?.file_url?.split("/").pop(), 20))}
                                  </a>
                                </div>

                                <div>
                                  {
                                    fileURL?.file_url ? 
                                    <a
                                      onClick={() => handleDownload(fileURL?.file_url)}
                                      className="btn btn-primary rounded-circle"
                                      title="Download"
                                      style={{ marginRight: 10 }}
                                    >
                                      <i className="fal fa-download"></i>
                                    </a> 
                                    :
                                    <a
                                      href={fileURL}
                                      download
                                      className="btn btn-primary rounded-circle"
                                      title="Download"
                                      style={{ marginRight: 10 }}
                                    >
                                      <i className="fal fa-download"></i>
                                    </a>
                                  }

                                  {openTabs == "assessmentMarking" && <button
                                    type="button"
                                    className="btn btn-danger rounded-circle"
                                    title="Delete"
                                    onClick={() => handleDelete(index, 'document' , fileURL?.id)}
                                  >
                                    <i className="fal fa-trash-alt"></i>
                                  </button>}
                                </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>}

          {studentData?.getSubmissionPracticalQuestionsBank?.evidence_lecturer_observation == 1 && <div className="col-md-6">
            <div className={`d-flex mb-3 align-items-center`} title="Confirm Lecturer Observation for Criteria">
              <label className='mb-0'>
                Confirm Lecturer Observation for Criteria
              </label>
              <div className="toggle-switch ml-2">
                <label className="switch">
                  <input
                    name='lecturer_observation_toggle'
                    id='lecturer_observation_toggle'
                    type="checkbox"
                    checked={formik.values?.lecturer_observation_toggle == 1}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "lecturer_observation_toggle",
                        e.target.checked ? 1 : 0
                      )
                    }
                  />
                  <span className="slider slider-round"></span>
                </label>
              </div>
            </div>
            {formik.values.lecturer_observation_toggle == 1 && studentData?.observation_date != 0 && studentData?.observation_date != null ? <p style={{ marginTop: 15 }} title={`Confirmed on ${formatDate(studentData?.observation_date)} at ${formatTime(studentData?.observation_date)} by ${studentData?.observationBy?.fullname}`}><i className="fal fa-history" style={{ color: "var(--topbar-color)" }}></i> {`Confirmed on ${formatDate(studentData?.observation_date)} at ${formatTime(studentData?.observation_date)} by ${studentData?.observationBy ? studentData?.observationBy?.fullname : ""}`}</p> : null}
          </div>}

          {/* Feedback Section */}
          <div className="col-12">
            <div className="form-group-blk mb-3" title="Feedback">
              <label htmlFor="user_feedback">Feedback</label>
              <input
                type="text"
                className="form-control"
                placeholder="Criteria Feedback"
                name="user_feedback"
                id="user_feedback"
                onChange={(e) => {
                    formik.setFieldValue("user_feedback", e.target.value);
                  }}
                value={formik.values.user_feedback !== null ? formik.values.user_feedback : ""}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

          {openTabs != "assessmentMarking" && subType != "openStudentAssessment" &&
          <FileUploadField
            name="user_feedback_file"
            label="Upload Feedback"
            formik={formik}
            col_md={12}
          />
          }
        </div>

        <div className="form-group form-group-save-cancel">
          {/* <PermissionsGate RenderError={() => (
            <button className="btn btn-save btn-success" disabled>
              <i className="fal fa-save"></i> Save
            </button>
          )} scopes={[type == "create" ? "wbssitewebsiteadd" : "wbssitewebsiteedit"]}> */}

          <button className="btn btn-save btn-success" type="submit" title="Save" disabled={loading}>
            {loading ? <i className="fas fa-cog fa-spin"></i> : 
              <i className="fal fa-save"></i>
            } 
            Save
          </button>
          {/* </PermissionsGate> */}

          {<button className="btn btn-close btn-danger" type="button" title="Download All" disabled={downloadLoading} onClick={() => handleDownloadAll()} data-target="#downloadAssessmentAll" data-toggle="modal">
            <i className="fal fa-download"></i>
            Download All
          </button>}

          <button className="btn btn-close btn-danger" type="button" title="Cancel" data-dismiss="modal">
            <i className="fal fa-times"></i>
            Cancel
          </button>
        </div>
      </form>}

      {/* Downloading Progress Modal */}
      <div className="downloading-progress modal fade" id="downloadAssessmentAll" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="swal2-header">
                <div className="swal2-icon swal2-info swal2-icon-show d-flex">
                  <div className="swal2-icon-content">i</div>
                </div>
                <h2 className="swal2-title d-flex mb-4">Downloading...</h2>
                <div className="progress w-100 mb-2">
                  <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={downloadProgress} aria-valuemin="0" aria-valuemax="100" style={{ width: `${downloadProgress}%` }}>
                    {downloadProgress}%
                  </div>
                </div>
                <p>File Size: {downloadedSize}/{totalSizeFormatted} Pending Files: {filesDownloaded}/{totalFiles}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CriteriaSubmission;
